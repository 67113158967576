// import qs from "qs";
import urls from "./urls.json";
import { axios } from "@/services/axios";

/**
 * Получение данных о текущем авторизованно пользователе
 * @returns
 */
export const getUser = async () => {
  return axios.get(urls.me);
};

export default {
  getUser,
};
