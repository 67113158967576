/**
 * Отсечение значений после запятой без округления
 * @param {Number} value исходное значение
 * @param {Number} n число цифр отображаемых после запятой 
 * @returns 
 */
export const toPrecisionNumber = (value, n = 0) => {
  const N = 10 ** n;
  return Math.floor(value * N) / N;
};

/**
 * Отсечение значений после запятой без округления
 * @param {Number} n число цифр отображаемых после запятой
 * @returns 
 */
Number.prototype.izToPrecision = function(n) {
  return toPrecisionNumber(this, n);
};
