import axios from "axios";
import { getToken, getRefreshToken, logout, refreshToken } from "services/auth";

import authURL from "services/api/modules/auth/urls.json";
import { mergeValueInObject } from "assets/js/utils";

const baseURL = `${process.env.VUE_APP_BACKEND_HOST}/api`;
const instance = axios.create({
  baseURL,
  headers: {},
});

/**
 * Прослойка перед запросом на сервер
 */
instance.interceptors.request.use((request) => {
  if (request.url !== authURL.login && request.url.startsWith("/")) {
    const token = request.url === authURL.refresh ? getRefreshToken() : getToken();

    if (token) {
      request.headers.common["Authorization"] = `Bearer ${token}`;
    }
  }

  return request;
});

let lockRefresh = false;
let promiseRefresh = null;
const logoutLocation = () => {
  logout();
  setTimeout(() => {
    if (document.location.pathname !== "/auth") {
      document.location.replace("/auth");
    }
  }, 2000);
};
/**
 * Прослойка после получения данных от сервера
 */
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response) {
      const originalRequest = error.config;
      const isAuthPage = document.location.pathname === "/authorization";

      switch (error.response.status) {
        case 401: {
          if (!isAuthPage && originalRequest.url !== authURL.refresh) {
            if (!lockRefresh) {
              lockRefresh = true;
              const { access_token } = await (promiseRefresh = refreshToken()
                .catch(() => ({}))
                .finally(() => {
                  lockRefresh = false;
                  promiseRefresh = null;
                }));

              if (access_token) {
                originalRequest.headers = {
                  ...originalRequest.headers,
                  Authorization: `Bearer ${access_token}`,
                };
                return axios(originalRequest);
              } else {
                logoutLocation(error.response.data?.message);
              }
            } else if (promiseRefresh) {
              const { access_token } = await promiseRefresh;
              if (access_token) {
                originalRequest.headers = {
                  ...originalRequest.headers,
                  Authorization: `Bearer ${access_token}`,
                };
                return axios(originalRequest);
              }
            }
          }
          break;
        }

        case 403: {
          if (document.location.pathname !== "/errors/403") {
            document.location.replace("/errors/403");
          }
          break;
        }

        case 422: {
          // Проблема валидации
          if (Array.isArray(error.response.data.detail)) {
            const result = {};
            error.response.data.detail.forEach((detail) => {
              if (detail?.loc?.length) {
                const target = detail.loc.join("-");
                result[target] = detail.msg;
              }
            });
            mergeValueInObject(error.response, "responseMetaData", { validationErrors: result });
          }
          break;
        }
      }
    }
    return Promise.reject(error);
  }
);

export { instance as axios, baseURL };

export default instance;
