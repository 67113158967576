/**
 * Генератор уникального числа
 */
function* generateID() {
  let _index = 0;
  while (true) {
    yield _index++;
  }
}

const _generate = generateID();

export default function () {
  return _generate.next().value;
}
