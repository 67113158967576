import urls from "./urls.json";

import { axios } from "services/axios";

/**
 * Получение списка timezones
 * @param {Object} params
 * @returns
 */
export const getTimezones = (params) => {
  return axios.get(urls.timezones, { params });
};

export default {
  getTimezones,
};
