import api from "api";
import { isObject } from "assets/js/utils";
import { cloneDeep } from "lodash-es";

const ADMIN_ROLE_NAME = "admin";

const state = () => ({
  loadings: {
    roles: true,
  },

  // Текущий пользователь приложения
  user: null,

  // Список всех ролей пользователей
  roles: null,
});

const getters = {
  user(state) {
    const user = cloneDeep(state.user);
    if (user) {
      const fullName = [user.first_name, user.middle_name, user.last_name].filter((v) => !!v).join(" ") || user.email;
      user.full_name = fullName;
    }

    return user;
  },

  isReadyUser(state) {
    return state.user && state.roles;
  },

  /**
   * Флаг определения пользователя как администратора
   * @returns {Boolean}
   */
  isAdmin(state, getters) {
    if (!getters.isReadyUser) return false;

    const roles = state.user.roles;
    /**
     * Если для текущего пользователя, есть хотя бы одно правило из блока администрирования.
     * То он считается администратором
     */
    return roles.some(({ name }) => name === ADMIN_ROLE_NAME);
  },
};

const mutations = {
  setCurrentUser(state, data) {
    state.user = data;
  },

  /**
   * Обновление списка ролей системы
   * @param {Array} roles
   */
  setRoles(state, roles) {
    state.roles = roles !== null ? roles || [] : null;
  },

  setLoadings(state, data) {
    if (!isObject(data)) return;

    for (let key in data) {
      state.loadings[key] = !!data[key];
    }
  },
};

const actions = {
  /**
   * Загрузка данных текущего пользователя по access_token
   * @returns
   */
  async loadCurrentUser({ commit }) {
    return api("Auth/getCurrentUser").then((response) => {
      commit("setCurrentUser", { ...response.data });
      return response.data;
    });
  },

  /**
   * Получение списка ролей пользователей
   * @returns
   */
  async loadRoles({ commit }) {
    commit("setLoadings", { roles: true });
    return api("Admin/Roles/getRoles").then((response) => {
      commit("setRoles", [...response.data]);
      commit("setLoadings", { roles: false });
      return response.data;
    });
  },

  /**
   * Очистка хранилища от данных текущего пользователя
   */
  resetUser({ commit }) {
    commit("setCurrentUser", null);
    commit("setRoles", null);
  },

  /**
   * Запрос на сброс пароля
   * @param {Object} postData
   * @returns
   */
  async resetPassword(_, postData) {
    return api("Auth/resetPassword", postData);
  },

  /**
   * Запрос на установление нового пароля для пользователя
   * @param {String} password
   * @param {String} token
   * @returns
   */
  async setPassword(_, { password, token }) {
    return api("Auth/setPassword", { password }, { token });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
