// import qs from "qs";
import urls from "./urls.json";
import { axios } from "@/services/axios";

/**
 * Авторизация пользователя
 * @param {Object} postData данные для запроса
 */
export const login = async (postData) => {
  return axios.post(urls.login, postData);
};

/**
 * Получение данных о текущем авторизованно пользователе
 * @returns
 */
export const getCurrentUser = async () => {
  return axios.get(urls.currentUser);
};

/**
 * Регистрация нового пользователя
 * @param {Object} postData
 * @returns
 */
export const registration = async (postData) => {
  return axios.post(urls.registration, postData);
};

/**
 * Запрос обновления access/refresh tokens
 * @param {Object} postData
 * @returns
 */
export const refresh = async (postData) => {
  return axios.post(urls.refresh, postData);
};

/**
 * Запрос на генерацию сообщения с ссылкой на email для сброса пароля
 * @param {Object} postData
 * @returns
 */
export const resetPassword = async (postData) => {
  return axios.post(urls.resetPassword, postData);
};

/**
 * Запрос назначения нового пароля для пользователя
 * @param {Object} postData
 * @param {Object} options
 * @param {String} options.token
 * @returns
 */
export const setPassword = async (postData, options) => {
  return axios.post(urls.setPassword, postData, { headers: { Authorization: `Bearer ${options.token}` } });
};

export default {
  login,
  registration,
  getCurrentUser,
  refresh,
  resetPassword,
  setPassword,
};
