import { createApp } from "vue";
import { sync } from "vuex-router-sync";
import App from "./App";
import generateRouter from "./router";
import store from "./store";
import i18n from "@/plugins/i18n";

/**
 * Директива блокировкания autocomplete элементов
 */
import DisableAutocomplete from "@/directives/disableAutocomplete";
import ClickOutside from "@/directives/clickOutside";

import { notification } from "@/services/notification";

window.bslNotice = notification;

const router = generateRouter(store);
sync(store, router);

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .directive("disable-autocomplete", DisableAutocomplete)
  .directive("click-outside", ClickOutside)
  .mount("#app");
