import Auth from "./modules/auth";
import Common from "./modules/common";
import Users from "./modules/users";
// import Profile from "./modules/profile";

const messages = {
  ru: {
    message: {
      auth: Auth.ru,
      common: Common.ru,
      users: Users.ru,
      // profile: Profile.ru,
    },
  },
};

export default messages;
