import urls from "./urls.json";
import { axios } from "@/services/axios";
import { urlEditor } from "assets/js/utils";

/**
 * Получение списка Пользователей
 * @param {Object} data данные для запроса
 */
export const getUsers = async (params) => {
  return axios.get(urls.list, { params });
};

/**
 * Создать пользователя
 * @param {Object} data Данные для создания пользователя
 */
export const createUser = async (data) => {
  return axios.post(urls.create, data);
};

/**
 * Обноление Пользователя
 * @param {Object} data Данные обновления
 * @param {Object} options
 * @param {Number} options.id Идентификатор Пользователя
 */
export const updateUser = async (data, options) => {
  const url = urlEditor(urls.user, { user_id: options.id });
  return axios.put(url, data);
};

/**
 * Удалить пользователя
 * @param {Object} options
 * @param {Number} options.id Идентификатор Пользователя
 * @returns
 */
export const deleteUser = async (options) => {
  const url = urlEditor(urls.user, { user_id: options.id });
  return axios.delete(url);
};

/**
 * Запрос на генерацию ссылки invite для пользователя
 * @param {Object} options
 * @param {Number} options.id Идентификатор Пользователя
 * @returns
 */
export const inviteUser = async (options) => {
  const url = urlEditor(urls.invite, { user_id: options.id });
  return axios.post(url);
};

export default {
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  inviteUser,
};
