import { isObject } from "assets/js/utils";
import api from "api";

const state = () => ({
  loadings: {
    create: false,
    update: false,
    delete: false,
    users: true,
    roles: false,
    timezones: false,
    extendedData: false,
  },

  users: [],
  roles: [],
  timezones: [],

  editorUserData: null,
});

const getters = {
  routeQueryNormalize(_, __, ___, rootGetters) {
    const routeQuery = rootGetters.routeQuery;
    return {
      search: routeQuery.search ?? "",
    };
  },

  // роли пользователей в системе
  roles: (state) => state.roles,

  // список таймзон пользователя
  timezones: (state) => state.timezones,

  // Генерируемый объект для запроса списка пользоваталей
  getMainSendData(_, getters) {
    const routeQueryNormalize = getters.routeQueryNormalize;
    const result = {
      search: routeQueryNormalize.search || undefined,
    };
    return result;
  },

  // Временные данные модели пользователя создание/редактирование
  editorUserData(state) {
    return state.editorUserData;
  },
};

const mutations = {
  setLoadings(state, data) {
    if (!isObject(data)) return;

    for (let key in data) {
      state.loadings[key] = Boolean(data[key]);
    }
  },

  updateStoreValues(state, data) {
    if (!isObject(data)) return;

    for (let key in data) {
      state[key] = data[key];
    }
  },

  setEditorUserData(state, data) {
    state.editorUserData = data;
  },
};

const actions = {
  /**
   * Очищение состояния страницы
   */
  resetState({ commit }) {
    commit("updateStoreValues", {
      users: [],
      editorUserData: null,
    });
    commit("setLoadings", { users: true });
  },

  /**
   * Получение списка пользователей
   * @params дополнительные параметры запроса
   * @returns
   */
  async loadUsers({ commit }, params) {
    commit("setLoadings", { users: true });
    return api("Admin/Users/getUsers", params)
      .then((response) => {
        commit("updateStoreValues", {
          users: [...response.data],
        });
        return response.data;
      })
      .finally(() => {
        commit("setLoadings", { users: false });
      });
  },

  /**
   * Получение списка ролей пользоваталей
   * @returns
   */
  async loadRoles({ commit }) {
    commit("setLoadings", { roles: true });
    return api("Admin/Roles/getRoles")
      .then((response) => {
        commit("updateStoreValues", {
          roles: [...response.data],
        });
      })
      .finally(() => {
        commit("setLoadings", { roles: false });
      });
  },

  /**
   * Получение списка таймзон пользователя
   * @returns
   */
  async loadTimezones({ commit }) {
    commit("setLoadings", { timezones: true });
    return api("System/getTimezones")
      .then((response) => {
        commit("updateStoreValues", {
          timezones: response.data.sort().map((timezone) => ({ id: timezone, name: timezone })),
        });
      })
      .finally(() => {
        commit("setLoadings", { timezones: false });
      });
  },

  /**
   * Загрузка всех дополнительных данных, которые не влияют на основное отображение страницы
   * @returns
   */
  async loadExtendedData({ commit, dispatch }) {
    commit("setLoadings", { extendedData: true });
    return Promise.all([dispatch("loadRoles"), dispatch("loadTimezones")]).finally(() => {
      commit("setLoadings", { extendedData: false });
    });
  },

  /**
   * Запрос создания нового пользователя
   * @param {Object} data модель данных нового пользователя
   * @returns
   */
  async createUser({ state, commit }, data) {
    commit("setLoadings", { create: true });
    return api("Admin/Users/createUser", data)
      .then((response) => {
        commit("updateStoreValues", {
          users: [response.data, ...state.users],
        });
        return response.data;
      })
      .finally(() => {
        commit("setLoadings", { create: false });
      });
  },

  /**
   * Запрос обновления кокретного пользователя
   * @param {String} id идентификатор пользователя
   * @param {Object} data обновляемые данные пользователя
   * @returns
   */
  async updateUser({ state, commit }, { id, data }) {
    commit("setLoadings", { update: true });
    return api("Admin/Users/updateUser", data, { id })
      .then((response) => {
        const newListUsers = [...state.users];
        const findIndex = newListUsers.findIndex((user) => user.id === id);

        if (findIndex > -1) {
          newListUsers.splice(findIndex, 1, response.data);
        } else {
          newListUsers.unshift(response.data);
        }
        commit("updateStoreValues", {
          users: newListUsers,
        });
        return response.data;
      })
      .finally(() => {
        commit("setLoadings", { update: false });
      });
  },

  /**
   * Запрос на удаление пользователя
   * @param {String} id идентификатор пользователя
   * @returns
   */
  async deleteUser({ state, commit }, id) {
    commit("setLoadings", { delete: true });
    return api("Admin/Users/deleteUser", { id })
      .then((response) => {
        const newListUsers = [...state.users];
        const findIndex = newListUsers.findIndex((user) => user.id === id);

        if (findIndex > -1) {
          newListUsers.splice(findIndex, 1);
        }
        commit("updateStoreValues", {
          users: newListUsers,
        });
        return response;
      })
      .finally(() => {
        commit("setLoadings", { delete: false });
      });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
