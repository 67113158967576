import { createRouter, createWebHistory, isNavigationFailure, NavigationFailureType } from "vue-router";
import { t } from "@/plugins/i18n";
import { nextTick } from "vue";
import authService from "@/services/auth";
import Auth from "./auth";
import Errors from "./errors";
import Home from "./home";
import Users from "./users";
// import Profile from "./profile";

const DEFAULT_TITLE_APPLICATION = "3D Office";

const notFailureTypes = [NavigationFailureType.duplicated];

/**
 * Проверка доступности роутера по правам пользователю
 * @param {Store<any>} store Vuex.Store
 * @param {Function|Array} permissions Правила или функция дающая результат с входящим аргументом store
 * @returns {Boolean}
 */
const isRoutePermissed = (store, permissions) => {
  if (typeof permissions === "function") {
    return permissions(store);
  }

  return true;
};

export default function generateRouter(store) {
  const routeRoot = {
    path: "",
    name: "Root",
    beforeEnter() {
      // Путь до начальной страницы в зависимости от прав.
      let toNext = { name: "Home" };
      const _routesPermissions = [
        {
          route: { name: "Users" },
          permissions: (_store) => _store.getters["Auth/isAdmin"],
        },
      ];

      for (let i = 0; i < _routesPermissions.length; i++) {
        if (isRoutePermissed(store, _routesPermissions[i].permissions)) {
          toNext = _routesPermissions[i].route;
          break;
        }
      }

      router.replace(toNext).catch((error) => {
        if (notFailureTypes.every((key) => !isNavigationFailure(error, key))) {
          Promise.reject(error);
        }
      });
    },
  };

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
      routeRoot,
      ...Auth,
      ...Errors,
      ...Home,
      ...Users,
      // ...Profile,
      {
        path: "/:pathMatch(.*)*",
        redirect: { name: "ErrorPage404" },
      },
    ],
  });

  router.beforeEach((to, _, next) => {
    const isGuest = authService.isGuest();
    const goToAuthorization = () => {
      if (to.name !== "Auth") {
        next({ name: "Auth" });
      } else {
        next();
      }
    };

    if (isGuest) {
      // Если пользователь неавторизован, ему доступны только страницы без доступа к авторизации
      if (to.meta.requiresAuth) {
        goToAuthorization();
        return;
      }
    } else {
      const _nextStepPermissions = () => {
        if (to.name === "Root") {
          next();
        } else {
          if (isRoutePermissed(store, to.meta.permissions)) {
            next();
          } else {
            next({ name: "Root" });
          }
        }
      };

      const _nextStepUser = () => {
        /**
         * Если мы получили данные о пользователе и он по каким-то причинам неактивный.
         * То производим разлогинивание.
         **/
        if (!store.state.Auth.user || store.state.Auth.user.is_active === false) {
          authService.logout();
          store.dispatch("Auth/resetUser");
          goToAuthorization();
          return;
        }

        if (to.name === "Auth") {
          next({ name: "Root" });
        } else {
          if (!store.state.Auth.roles) {
            const promises = [];
            if (!store.state.Auth.roles) {
              promises.push(store.dispatch("Auth/loadRoles"));
            }
            Promise.all(promises)
              .then(() => _nextStepPermissions())
              .catch((error) => {
                // Если ошибка, вызываю уведомление
                window.bslNotice({ type: "error", data: { message: error.toString() } });
                if (["ErrorPage500", "ErrorPage404", "ErrorPage403"].includes(to.name)) {
                  next();
                } else {
                  next({ name: "ErrorPage500" });
                }
              });
          } else {
            _nextStepPermissions();
          }
        }
      };

      /**
       * Если пользователь имеет ключ доступа,
       * то нужно проверить есть ли информация о полльзователе в хранилище,
       * иначе запросить ее
       */
      if (!store.state.Auth.user) {
        store
          .dispatch("Auth/loadCurrentUser")
          .then((user) => {
            const username =
              user.username || [user.first_name, user.middle_name, user.last_name].filter((v) => !!v).join(" ");

            window.bslNotice({
              data: {
                placement: "bottomLeft",
                message: `${t("message.common.notice.welcome")} ${username}`,
                duration: 2,
                style: {
                  left: "0",
                  position: "fixed",
                  bottom: "0",
                  margin: "0",
                  width: "100vw",
                  "text-align": "center",
                  "max-width": "100vw",
                },
                closeIcon: "",
              },
            });
            /**
             * После успешного получения данных о текущем пользователе
             * переходим к следующему шагу проверок.
             */

            _nextStepUser();
          })
          .catch((error) => {
            if (error.response) {
              // Если ошибка вызвана неправильным ключем авторизации, мы стираем все неправильные данные
              if ([400, 401].includes(error.response.status)) {
                authService.logout();
                store.dispatch("Auth/resetUser");
              } else {
                window.bslNotice({ type: "error", data: { message: error.toString() } });
              }
            } else {
              // Если ошибка вызывана по другой причине, вызываю уведомление
              window.bslNotice({ type: "error", data: { message: error.toString() } });
              next({ name: "ErrorPage500" });
              return;
            }

            goToAuthorization();
          });
      } else {
        /**
         * Если данные о пользователе уже есть,
         * то переходим к следующему шагу проверок.
         */
        _nextStepUser();
      }

      return;
    }
    next();
  });

  router.afterEach((to) => {
    nextTick(() => {
      document.title = to.meta.title || DEFAULT_TITLE_APPLICATION;
    });
  });

  router.onError((error) => {
    console.warn("router error: ", error);
  });

  return router;
}
