<template>
  <div id="app-main">
    <component :is="isComponent"></component>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { defineAsyncComponent, computed } from "vue";
const DefaultLayout = defineAsyncComponent(() =>
  import(/* webpackChunkName: "DefaultLayout" */ "@/views/Layouts/default")
);
const EmptyLayout = defineAsyncComponent(() =>
  import(/* webpackChunkName: "RouterViewLayout" */ "@/views/Layouts/RouterViewLayout")
);

export default {
  components: {
    // Modals,
  },

  setup() {
    const route = useRoute();

    // data
    const dictLayouts = {
      default: DefaultLayout,
      empty: EmptyLayout,
    };

    // computed
    const isComponent = computed(() => dictLayouts[route.meta.layout] || EmptyLayout);

    return {
      isComponent,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
@import "~ant-design-vue/dist/antd.min.css";
@import "~ant-design-vue/dist/antd.variable.min.css";
body {
  --vo-background-color: #f0f2f5;
  --vo-black: #{$black};
  --vo-black-85: rgba(0, 0, 0, 0.85);
  --vo-white: #{$white};

  --vo-disabled: rgba(0, 0, 0, 0.25);
  --vo-placeholder: rgba(0, 0, 0, 0.25);

  --vo-divider-border: rgba(0, 0, 0, 0.06);
  --vo-required-red: #ff4d4f;

  --vo-font-size: 1.6rem;

  background-color: var(--vo-background-color);
  color: var(--vo-black-85);
  font-size: var(--vo-font-size);

  --ant-primary-color: #1890ff;
  --ant-primary-color-hover: #40a9ff;
  --ant-primary-color-active: #096dd9;
  --ant-primary-color-outline: rgba(24, 144, 255, 0.2);
  --ant-primary-1: #e6f7ff;
  --ant-primary-2: #bae7ff;
  --ant-primary-3: #91d5ff;
  --ant-primary-4: #69c0ff;
  --ant-primary-5: #40a9ff;
  --ant-primary-6: #1890ff;
  --ant-primary-7: #096dd9;
  --ant-primary-color-deprecated-pure: ;
  --ant-primary-color-deprecated-l-35: #cbe6ff;
  --ant-primary-color-deprecated-l-20: #7ec1ff;
  --ant-primary-color-deprecated-t-20: #46a6ff;
  --ant-primary-color-deprecated-t-50: #8cc8ff;
  --ant-primary-color-deprecated-f-12: rgba(24, 144, 255, 0.12);
  --ant-primary-color-active-deprecated-f-30: rgba(230, 247, 255, 0.3);
  --ant-primary-color-active-deprecated-d-02: #dcf4ff;
  --ant-success-color: #52c41a;
  --ant-success-color-hover: #73d13d;
  --ant-success-color-active: #389e0d;
  --ant-success-color-outline: rgba(82, 196, 26, 0.2);
  --ant-success-color-deprecated-bg: #f6ffed;
  --ant-success-color-deprecated-border: #b7eb8f;
  --ant-error-color: #ff4d4f;
  --ant-error-color-hover: #ff7875;
  --ant-error-color-active: #d9363e;
  --ant-error-color-outline: rgba(255, 77, 79, 0.2);
  --ant-error-color-deprecated-bg: #fff2f0;
  --ant-error-color-deprecated-border: #ffccc7;
  --ant-warning-color: #faad14;
  --ant-warning-color-hover: #ffc53d;
  --ant-warning-color-active: #d48806;
  --ant-warning-color-outline: rgba(250, 173, 20, 0.2);
  --ant-warning-color-deprecated-bg: #fffbe6;
  --ant-warning-color-deprecated-border: #ffe58f;
  --ant-info-color: #1890ff;
  --ant-info-color-deprecated-bg: #e6f7ff;
  --ant-info-color-deprecated-border: #91d5ff;
}

#app-main {
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
}
</style>
