import { notification as Notification } from "ant-design-vue";
import { isObject } from "assets/js/utils";

export const ENUM_NOTICE_TYPE = {
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
  INFO: "info",
};

/**
 * Преднастройки для каждого типа уведомления
 */
const settingsType = {
  [ENUM_NOTICE_TYPE.SUCCESS]: {
    customClass: "vo-notification_success",
  },

  [ENUM_NOTICE_TYPE.ERROR]: {
    customClass: "vo-notification_error",
  },

  [ENUM_NOTICE_TYPE.WARNING]: {
    customClass: "vo-notification_warning",
  },

  [ENUM_NOTICE_TYPE.INFO]: {
    customClass: "vo-notification_info",
  },
};

Notification.config({
  placement: "topRight",
  top: "50px",
  duration: 5,
  rtl: false,
  key: "updatable",
});

const types = Object.values(ENUM_NOTICE_TYPE);
export const notification = async (options) => {
  return new Promise((resolve) => {
    if (!isObject(options)) return;

    const { type, data, onOpen, onClose, showIcon } = options;
    const settings = settingsType[type] ?? {};
    let customClass = `vo-notification ${data?.customClass ?? ""}`;

    if (showIcon === false) {
      customClass += " vo-notification_without-icon";
    }

    const notice = {
      duration: 5,
      onClose,
      ...data,
      class: `${settings.customClass} ${customClass}`,
    };

    setTimeout(() => {
      const instance = types.includes(type) ? Notification[type](notice) : Notification.open(notice);
      if (typeof onOpen === "function") {
        onOpen(instance);
      }
      resolve(instance);
    }, 100);
  });
};

export default notification;
