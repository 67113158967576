import { t } from "@/plugins/i18n";

const AuthLayoutPage = () => import(/* webpackChunkName: "RouterViewLayout" */ "@/views/Layouts/RouterViewLayout");
const AuthPage = () => import(/* webpackChunkName: "AuthPage" */ "@/views/Auth");
const NewPasswordPage = () => import(/* webpackChunkName: "AuthPage" */ "@/views/NewPassword");
const basePath = "/auth";

export default [
  {
    path: basePath,
    component: AuthLayoutPage,
    meta: {
      _routeName: "Auth",
      title: t("message.auth.namePage"),
    },
    children: [
      {
        path: "",
        name: "Auth",
        component: AuthPage,
        meta: {
          title: t("message.auth.namePage"),
        },
      },
    ],
  },
  {
    name: "NewPassword",
    path: "/reset-password",
    component: NewPasswordPage,
    meta: {
      title: t("message.auth.newPasswordPage"),
    },
    beforeEnter: (to) => {
      if (!to.query.token) {
        return { name: "Auth" };
      }
    },
  },
  {
    name: "Invite",
    path: "/invite",
    component: NewPasswordPage,
    meta: {
      title: t("message.auth.invitePage"),
    },
    beforeEnter: (to) => {
      if (!to.query.token) {
        return { name: "Auth" };
      }
    },
  },
];
