import { createStore } from "vuex";

import Auth from "./modules/auth";
import Admin from "./modules/admin";
// import Profile from "./modules/profile";

const getters = {
  routeQuery(state) {
    return state.route?.query || {};
  },
};

export default createStore({
  getters,
  modules: {
    Auth,
    Admin,
    // Profile,
  },
});
