/**
 * Метод добавляет новые свойтсва к существующему объекту определенного поля
 * @param {Object} object 
 * @param {String} field 
 * @param {Object} values 
 * @returns {Void}
 */
export const mergeValueInObject = (object, field, values) => {
  if (!(object && field && values)) return;

  if (!(field in object)) {
    object[field] = {};
  }

  Object.assign(object[field], values);
};

export default {
  mergeValueInObject,
};