import { t } from "@/plugins/i18n";

const HomePage = () => import(/* webpackChunkName: "HomePage" */ "@/views/Home");
const basePath = "/home";

export default [
  {
    name: "Home",
    path: basePath,
    component: HomePage,
    meta: {
      title: t("message.common.home.title"),
      layout: "default",
      requiresAuth: true,
    },
  },
];
