/**
 * Шаблон определения хорошего email для валидации
 */
/* eslint-disable-next-line */
export const emailRegexp = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

export const passwordRegexp = /^[a-zA-Z0-9-_.]+$/;
// #endregion

export default {
  emailRegexp,
  passwordRegexp,
};
