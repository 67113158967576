import { isObject } from "utils";
import Auth from "./modules/auth";
import Admin from "./modules/administration";
import Profile from "./modules/profile";
import System from "./modules/system";

/**
 * Генерация списка зависимостей из древовидного вида в плоский
 * посредством генерации имени через конкатинацию строк и разделителем /
 * @param {Object} elements
 * @returns {Object}
 */
const createList = (elements) => {
  const result = {};

  const recursiveNode = (obj, parentName) => {
    if (!isObject(obj)) return obj;
    for (let key in obj) {
      const newElementName = parentName ? `${parentName}/${key}` : key;
      if (isObject(obj[key])) {
        recursiveNode(obj[key], newElementName);
      } else {
        result[newElementName] = obj[key];
      }
    }
  };
  recursiveNode(elements, "");
  return result;
};

const modules = {
  Auth,
  Admin,
  Profile,
  System,
};
const methods = createList(modules);

export const api = async (name, ...params) => {
  return new Promise((resolve, reject) => {
    if (name in methods) {
      resolve(methods[name](...params));
    }
    reject(new Error(`[API: ${name}] - method not exists`));
  });
};

export default api;
