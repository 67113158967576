import urls from "./urls.json";

import { axios } from "services/axios";

/**
 * Получение списка ролей
 * @param {Object} params
 * @returns
 */
export const getRoles = (params) => {
  return axios.get(urls.list, { params });
};

export default {
  getRoles,
};
