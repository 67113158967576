import messages from "@/assets/i18n";
import { createI18n } from "vue-i18n";

const locale = "ru";

const i18n = createI18n({
  legacy: false,
  locale,
  allowComposition: true,
  messages,
});

const t = i18n.global.t;

export { locale, i18n, t };
export default i18n;
