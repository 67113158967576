import cookie from "vue-cookies";

import api from "api";

export const ACCESS_TOKEN_NAME = "access_token";
export const REFRESH_TOKEN_NAME = "refresh_token";

/**
 * Проверка наличия access_token и опредление пользователя, как гостя
 * @returns
 */
export function isGuest() {
  const token = cookie.get(ACCESS_TOKEN_NAME);
  return !token;
}

/**
 * Сохранение Access/Refresh токенов в хранилищах среды
 */
export const saveTokens = ({ access_token, refresh_token }) => {
  if (refresh_token) {
    localStorage.setItem(REFRESH_TOKEN_NAME, refresh_token);
  }

  if (access_token) {
    cookie.set(ACCESS_TOKEN_NAME, access_token, Infinity);
  }
};

/**
 * Метод авторизации пользователя
 * @param {Object} postData
 * @returns
 */
export async function login(postData) {
  return api("Auth/login", postData).then((response) => {
    saveTokens(response.data);
    return response.data;
  });
}

/**
 * Получение актуального AccessToken
 * @returns
 */
export function getToken() {
  return cookie.get(ACCESS_TOKEN_NAME);
}

/**
 * Получение актуального RefreshToken
 * @returns
 */
export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_NAME);
}

/**
 * Регистрация пользователя
 * @param {Object} postData
 * @returns
 */
export async function registration(postData) {
  return api("Auth/registration", postData).then((response) => {
    saveTokens(response.data);
    return response.data;
  });
}

/**
 * Очищение хранилища среды от записанных данных о пользователе
 */
export function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem(REFRESH_TOKEN_NAME);
  cookie.remove(ACCESS_TOKEN_NAME);
}

/**
 * Метод обновления Access/Refresh tokens
 * @returns
 */
export const refreshToken = async () => {
  return api("Auth/refresh")
    .then((response) => {
      saveTokens(response.data);
      return response.data;
    })
    .catch((error) => {
      logout();
      return Promise.reject(error);
    });
};

export default {
  login,
  logout,
  isGuest,
  registration,
};
