const disableAutocomplete = {
  created(el) {
    if (!el) {
      return;
    }

    let elements = [...el.querySelectorAll('[autocomplete="off"]')].reduce((acc, element) => {
      const inputs = element.querySelectorAll("input");
      if (inputs.length > 0) {
        acc.push(...inputs);
      } else {
        acc.push(element);
      }
      return acc;
    }, []);

    if (!elements) {
      return;
    }

    elements.forEach((element) => {
      element.setAttribute("readonly", "readonly");

      setTimeout(() => {
        element.removeAttribute("readonly");
      }, 500);
    });
  },
};

export default disableAutocomplete;
