import { t } from "@/plugins/i18n";

const UsersPage = () => import(/* webpackChunkName: "UsersPage" */ "@/views/Users");
const basePath = "/users";
export default [
  {
    name: "Users",
    path: basePath,
    component: UsersPage,
    meta: {
      title: t("message.users.title"),
      requiresAuth: true,
      layout: "default",
      permissions: (store) => store.getters["Auth/isAdmin"],
    },
  },
];
