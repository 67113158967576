/**
 * Редактор url строки с параметрами
 * 
 * @param {String} str Строка запроса
 * @param {Object} params Параметры, вставляемые в строку запроса
 * @returns {String} Итоговый url
 * 
 * @example
 * // returns "/users/999/save/done"
 * urlEditor("/users/:id/:action/done", { id: 999, action: "save" });
 */
export const urlEditor = function(str, params) {
  let result = str;
  for (const param in params) {
    result = result.replace(`:${ param }`, params[param]);
  }
  return result;
};

export default {
  urlEditor,
};