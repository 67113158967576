const ErrorsPage = () => import(/* webpackChunkName: "RouterViewLayout" */ "@/views/Layouts/RouterViewLayout");
const ErrorPage403 = () => import(/* webpackChunkName: "ErrorPage403" */ "@/views/Errors/403");
const ErrorPage404 = () => import(/* webpackChunkName: "ErrorPage404" */ "@/views/Errors/404");
const ErrorPage500 = () => import(/* webpackChunkName: "ErrorPage500" */ "@/views/Errors/500");
const basePath = "/errors";

export default [
  {
    name: "Errors",
    path: basePath,
    component: ErrorsPage,
    redirect: { name: "ErrorPage404" },
    children: [
      {
        name: "ErrorPage403",
        path: "403",
        component: ErrorPage403,
        meta: {
          title: "403",
          layout: "default",
          requiresAuth: true,
        },
      },
      {
        name: "ErrorPage404",
        path: "404",
        component: ErrorPage404,
        meta: {
          title: "404",
        },
      },
      {
        name: "ErrorPage500",
        path: "500",
        component: ErrorPage500,
        meta: {
          title: "500",
        },
      },
    ],
  },
];
