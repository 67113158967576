import { t } from "@/plugins/i18n";

/**
 * Обработка ошибок запросов
 */
export const handleBackendError = (error) => {
  let message = t("message.common.notice.defaultError");

  if (error instanceof Error) {
    const response = error.response || {};
    let data = response.data || {};
    if (response.status === 422 && response.responseMetaData?.validationErrors) {
      data.detail = Object.keys(response.responseMetaData.validationErrors).reduce((acc, key) => {
        acc += `${key}: ${response.responseMetaData.validationErrors[key]}\n`;
        return acc;
      }, "");
    } else {
      if (data.error_backend) {
        const body = data.error_backend;
        if (body.http_body && typeof body.http_body === "string") {
          data = JSON.parse(body.http_body);
        }
      }
    }

    if (data.detail) {
      message = data.detail;
    } else if (data.message) {
      message = data.message;
    }
  }

  window.bslNotice({
    type: "error",
    data: {
      message,
    },
  });

  return message;
};

export default handleBackendError;
